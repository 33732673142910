@import "~aos/dist/aos.css";
@import "~react-accessible-accordion/dist/fancy-example.css";
@import "~slick-carousel/slick/slick.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "cabinet";
  src: url(./assets/fonts/CabinetGrotesk-Variable.ttf);
  font-style: normal;
  font-weight: 700;
}

/* reset toastify */
.Toastify__toast-container--top-right {
  top: 12% !important;
  transform: translateX(0);
}

.av-preview-container {
  width: 80vw !important;
  height: 50vh !important;
}

#meetingSDKElement {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#meetingSDKElement>div {
  width: 100vw !important;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiPaper-root {
  height: 100% !important;
  width: 100% !important;
}

.form-group {
  display: flex !important;
  justify-content: center !important;
}

.meeting-title {
  display: none !important;
}

.loading-main {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

:root {
  --black: #211E1D;
  --primary: #FFCC29;
  --secondary: #D3F2FF;
  --yellow: #FEFFF3;
  --green: #E5FAAF;
  --grey: rgba(33, 30, 29, .5);
  --black: rgba(33, 30, 29, 1);
  --white: #fff;
  --red: #ED3237;
  --shadow: 26px 64px 87px -21px rgba(0, 0, 0, 0.08);
  --shadow-two: 0px 40px 50px rgba(0, 0, 0, 0.05);
}

/* end */
html {
  overflow-x: hidden !important;

}

body {
  margin: 0;
  font-family: "cabinet";
  font-variation-settings: 'wght' 470;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  max-width: 100vw;
  background: #ffff;
  overflow-x: hidden;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.bold {
  font-variation-settings: 'wght' 820;
}

.semiBold {
  font-variation-settings: 'wght' 720;
}

.main {
  @apply w-[90%] lg:w-[80%] mx-auto
}

.tabActive {
  @apply bg-[var(--black)] w-fit rounded-full px-6 py-2
}

.tabInactive {
  @apply bg-[var(--grey)] w-fit rounded-full px-6 py-2
}

.pri_btn {
  @apply bg-[var(--primary)] rounded-full px-6 py-2 flex items-center gap-3 justify-center
}

.white_btn {
  @apply bg-[var(--white)] p-5 rounded-full px-6 py-2
}

.black_btn {
  @apply bg-[var(--black)] rounded-full px-6 py-2 flex items-center gap-3 justify-center
}

.bg-enrollment {
  background-image: url(assets/background-img/course-enrollment-bg.webp);
  background-size: cover;
  background-position: bottom;
}

.bg-learning {
  background-image: url(assets/background-img/mylearning-bg.png);
  background-size: cover;
  background-position: bottom;
}

h1 {
  @apply text-4xl lg:text-7xl bold
}

h2 {
  @apply text-2xl lg:text-4xl bold
}

.card {
  @apply text-start shadow-lg p-5 rounded-3xl text-sm
}

.box {
  @apply p-2 rounded-lg text-sm border h-[50px] border-[#C9C9C9]
}

.blue {
  @apply bg-[#D3F2FF] p-5 rounded-xl text-sm
}

.red {
  @apply bg-[#FFBCBE] p-5 rounded-xl text-sm
}

.yellow {
  @apply bg-[#FFE388] p-5 rounded-xl text-sm
}

.green {
  @apply bg-[#E5FAAF] p-5 rounded-xl text-sm
}

.bright-yellow {
  @apply bg-[var(--primary)] p-5 rounded-xl text-sm
}

.faded-yellow {
  @apply bg-[var(--primary)] p-5 rounded-xl text-sm opacity-[40%] cursor-pointer
}

.p-inputtext, .p-password, .p-dropdown {
  @apply !w-full !shadow-none
}

.profile-bg {
  background-image: url('./assets/background-img/profile-bg.webp');
  background-size: cover;
  background-position: bottom;
  object-fit: contain;
}

.accordion__button {
  @apply bg-white
}

.accordion__button:hover {
  @apply !bg-none
}

.accordion {
  @apply !border-none
}

.p-float-label input:focus~label, .p-float-label input:-webkit-autofill~label, .p-float-label input.p-filled~label, .p-float-label textarea:focus~label, .p-float-label textarea.p-filled~label, .p-float-label .p-inputwrapper-focus~label, .p-float-label .p-inputwrapper-filled~label, .p-float-label .p-tooltip-target-wrapper~label {
  top: 0.25rem;
  font-size: 12px;
  background: white;
}

:disabled {
  @apply bg-gray-300
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.otp__input {
  border: 1px solid rgb(193, 193, 193);
  width: 70px !important;
  height: 70px !important;
  border-radius: 5px;
  margin-left: 10px;
}


.overlay{
  @apply fixed h-screen w-screen bg-black/80 top-0 left-0 z-50 
}

