.chat__us{
    position: fixed;
    bottom:50px;
    right: 50px;
    z-index: 2;

}
@media screen and (max-width: 480px){
    .chat__us{
        right: 10px;
        bottom: 10px;
    }
}